@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700");
@import url("./../icons/simple-line-icons/css/simple-line-icons.css");
@import url("./../icons/font-awesome-old/css/font-awesome.min.css");
@import url("./../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("./../icons/themify-icons/css/themify-icons.css");
@import url("./../icons/line-awesome/css/line-awesome.min.css");
@import url("./../icons/avasta/css/style.css");
@import url("./../icons/flaticon/flaticon.css");
@import url("./../icons/icomoon/icomoon.css");
@import url(./../vendor/animate/animate.min.css);
@import url(./../vendor/aos/css/aos.min.css);
@import url(./../vendor/perfect-scrollbar/css/perfect-scrollbar.css);
@import url(./../vendor/metismenu/css/metisMenu.min.css);
 @import "~jsgantt-improved/dist/jsgantt.css";
.ln-brand-logo {
 /* width: 50px;*/
 width: 85px;
margin: auto;
}
  
.ln-dashboard-card {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ln-avatar {
  width: 55px;
  height: 55px;
  border-radius: 0.75rem;
  object-fit: cover;
}

button.calendar,
button.calendar:active {
  width: 2.75rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=) !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: 50%;
}

.ln-info-card {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 1rem;
  margin: 0.5rem 0;
}

.ln-pointer {
  cursor: pointer;
}

.custom-top-list {
  padding-top: 0px !important;
}

/* default-accordion */
.default-accordion {
  border: 1px solid #D8B9C3;
  border-radius: 15px !important;
}
.default-accordion:first-of-type, .default-accordion:not(:last-of-type) {
  border-bottom:  1px solid #D8B9C3 !important;
}
.default-accordion .card-header button {
  border-radius: 15px 0 0 0;
}
.default-accordion button span.text {
  color:#ffffff;
}
.default-accordion button.collapsed span.text {
  color:#212f54;
}
.default-accordion button.collapsed .badge-primary{
  color:#ffffff;
  background-color:#212f54;
}
.default-accordion button  .badge-primary{
  color:#212f54;
  background-color: #ffffff;
}
.card-lawyer-stepper .mat-step-icon {
  height: 35px!important;
  width: 35px!important;
}
.card-lawyer-stepper .mat-horizontal-stepper-header-container  {
  margin-bottom: 30px;
}
.cursor-pointer {
  cursor: pointer;
}

.swal2-actions {
  margin-top: 5px !important;
}
.swal2-html-container {
  font-size: 1em !important;
}

.light.btn-primary {
  background-color: #cac7e4 !important;
  border-color: #cac7e4 !important;
  color: #212F54 !important;
}

.light.btn-dark {
  background-color: #eee!important;
  border-color: #eee!important;
  color: #6e6e6e!important;
}

/**  **/
.gmainright {
  margin-left: -20%  !important;
}

.gtaskheading,
.gmajorheading,
.gminorheading {
  background-color: #ffffff;
  font-weight: bold;
  font-size: 0.7rem;
  white-space: nowrap;
}

.gtaskname div, .gtaskname {
  font-size: 1rem !important;
}

.gmajorheading { 
  font-size: 0.7rem !important;
}

.justify-content-center {
  display: flex !important;
  justify-content: center !important;
}

/* header */
.header-right .notification_dropdown .nav-link {
  border: 1px solid #c9c4c4;
}
.pulse-css {
  background: #c29738;
}